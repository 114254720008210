import { IProvidersProps } from './interfaces';
import { locationProviderPropsMap } from '../Location/locationProviderPropsMap';
import { generalDataProviderPropsMap } from '../GeneralDataProvider/generalDataProviderPropsMap';
import { IBiSettings } from './biInterfaces';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { loadingPropsMap } from '../Loading/loadingPropsMap';
import { errorHandlerPropsMap } from '../ErrorHandler/errorHandlerPropsMap';
import { localStoragePropsMap } from '../LocalStorage/localStoragePropsMap';
import { monitoringPropsMap } from '../Monitoring/MonitoringPropsMap';
import { quizPropsMap } from '../Quiz/QuizPropsMap';
import { toastPropsMap } from '../ToastContext/toastPropsMap';

interface IRootProviderPropsMap {
  biSettings?: IBiSettings;
  providers: ((flowAPI: ControllerFlowAPI) => any)[];
  flowAPI: ControllerFlowAPI;
}

export async function providersPropsMap(
  options: IRootProviderPropsMap,
): Promise<IProvidersProps> {
  const { providers = [], flowAPI } = options;

  const providersProps = {
    ...generalDataProviderPropsMap(flowAPI),
    ...locationProviderPropsMap(flowAPI),
    ...loadingPropsMap(flowAPI),
    ...localStoragePropsMap(flowAPI),
    ...monitoringPropsMap(flowAPI),
    ...quizPropsMap(flowAPI),
    ...toastPropsMap(flowAPI),
  };

  const asyncProviders: Promise<any>[] = [
    errorHandlerPropsMap(flowAPI),
    ...providers.map(async (provider) => {
      return provider(flowAPI);
    }),
  ];

  const data = await Promise.all(asyncProviders);
  data.forEach((providerData) => {
    if (!providerData) {
      return;
    }
    Object.entries(providerData).forEach(([key, value]) => {
      providersProps[key] = value;
    });
  });

  return providersProps as any;
}
