import {
  PaymentType,
  Participant,
} from '@wix/ambassador-challenges-v1-participant/types';
import { IPromptLogin, IUserType, UserState } from '../UserContext';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getParticipant } from './getParticipant';
import format from 'date-fns/format';
import { getDateFromString } from '../../../selectors/dates';
import { SelectedPaymentOption } from '../../../components/ChallengesPage/Widget/components/Pricing/interfaces';
import {
  getChallengeSlugFromLocation,
  locationProviderPropsMap,
} from '../../Location/locationProviderPropsMap';
import { Pages } from '../../Location/LocationContext';
import { IUserProviderProps } from '../UserProvider';
import app from '../../../../.application.json';
import { syncInstance } from '../../../services/instance';
import { getFromStorage } from '../../storage/storage';

export const getUserType = (
  user: { loggedIn: boolean },
  participant: Participant,
): IUserType => {
  if (!user.loggedIn) {
    return UserState.VISITOR;
  }

  if (!participant) {
    return UserState.MEMBER;
  }

  return participant.transitions[0].state;
};

export async function promptLogin(flowAPI: ControllerFlowAPI) {
  const { language } = flowAPI?.environment;

  await flowAPI.controllerConfig.wixCodeApi.user.promptLogin({
    lang: language || 'en',
    modal: true,
  } as IPromptLogin);
}

export type UpdatedUserData = Pick<
  IUserProviderProps,
  'user' & 'participant' & 'userType'
>;

export async function updateUserContext(
  flowAPI: ControllerFlowAPI,
  newParticipant?: Participant,
): Promise<UpdatedUserData> {
  const user = await getUserFromConfig(flowAPI.controllerConfig);
  const participant = newParticipant || (await getParticipant(flowAPI));
  return {
    user,
    participant,
    userType: getUserType(user, participant),
  };
}

export async function handleUserLogin(
  flowAPI: ControllerFlowAPI,
  userProvider: IUserProviderProps,
) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async () => {
    syncInstance(flowAPI);

    await userProvider.updateParticipant();
  });
}

export async function getUserFromConfig(
  config: ControllerFlowAPI['controllerConfig'],
) {
  const { currentUser } = config.wixCodeApi.user;
  let userEmail = '';

  if (currentUser?.loggedIn) {
    try {
      userEmail = await getFromStorage(
        config,
        `${currentUser.id}_email`,
        async () => currentUser.getEmail(),
      );
    } catch (error) {
      console.error('Error with getting user email', error);
    }
  }

  return {
    id: currentUser.id,
    loggedIn: currentUser.loggedIn,
    role: currentUser.role,
    instance: config.wixCodeApi.site.getAppToken(app.appDefinitionId),
    email: userEmail,
  };
}

export const toServerStartDate = (startDate?: string) => {
  return startDate ? format(getDateFromString(startDate), 'yyyy-MM-dd') : null;
};

export const getTimeZone = (flowAPI: ControllerFlowAPI) => {
  return typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function'
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : flowAPI.controllerConfig.wixCodeApi.site.timezone;
};

export function getPaymentType(
  selectedPaymentOption: SelectedPaymentOption,
): PaymentType {
  switch (selectedPaymentOption) {
    case SelectedPaymentOption.PaidPlans:
      return PaymentType.PAID_PLANS;
    default:
    case SelectedPaymentOption.SinglePayment:
      return PaymentType.SINGLE_PAYMENT;
  }
}

export async function navigateToThankYouPage(flowAPI: ControllerFlowAPI) {
  const location = locationProviderPropsMap(flowAPI);
  return location.goToPage({
    challengeId: getChallengeSlugFromLocation(flowAPI),
    pageId: Pages.ThankYou,
  });
}
