import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const getFromStorage = async <T extends any>(
  controllerConfig: ControllerFlowAPI['controllerConfig'],
  key: string,
  getter: () => Promise<T>,
): Promise<T> => {
  try {
    const storage = controllerConfig.platformAPIs.storage.memory;
    const value = storage.getItem(key);
    if (!value) {
      const val = await getter();
      storage.setItem(key, JSON.stringify(val));
      return val;
    }
    return JSON.parse(value);
  } catch (e) {
    console.error(e);
    return getter();
  }
};
